import { getAuth, checkActionCode, applyActionCode, sendPasswordResetEmail } from 'firebase/auth';
import { useEffect, useState, useContext } from 'react';
import { SiteType } from './App';

const RecoverEmail = () => {
    const { isPcSite } = useContext(SiteType);
    const [initCheck, setInitCheck] = useState<boolean>(false);
    const [resultFlag, setResultFlag] = useState<boolean>(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const oobCode = queryParams.get('oobCode') || '';
        if (oobCode) {
            let restoredEmail: string | null = null;
            // アクションコードが有効かどうかを確認
            checkActionCode(getAuth(), oobCode)
                .then((info) => {
                    // 復元されたメールアドレスを取得
                    restoredEmail = info.data.email ?? null;
                    // 古いメールアドレスに戻す
                    return applyActionCode(getAuth(), oobCode);
                })
                .then(() => {
                    if (restoredEmail) {
                        // パスワードリセットメールを送信
                        return sendPasswordResetEmail(getAuth(), restoredEmail);
                    } else {
                        throw new Error('No restored email available.');
                    }
                })
                .then(() => {
                    setResultFlag(true);
                })
                .catch((error) => {
                    console.log(error);
                    setResultFlag(false);
                })
                .finally(() => {
                    setInitCheck(true);
                });
        }
    }, []);

    if (initCheck) {
        return (
            <>
                <div id={isPcSite ? "formArea" : "formArea-Mobile"}>
                    <h1 className="title" id={isPcSite ? "PC" : "Mobile"}>Email復元</h1>
                    {resultFlag ? (
                        <div id="succeeded">
                            <p>メールアドレスが復元されました。</p>
                            <p>パスワードリセットのため、メールを確認してください。</p>
                        </div>
                    ) : (
                        <div id="failed">
                            <p>メールアドレスを復元できませんでした。</p>
                            <p>メールアドレスの確認のリクエストの期限が切れたか、リンクがすでに使用されています。</p>
                        </div>
                    )}
                </div>
            </>
        );
    } else {
        return <></>;
    }
};

export default RecoverEmail;
